import { useEffect, useState } from "react"

export default function useRenderizable (modalData, requiredFields) {
  const [renderizable, setRenderizable] = useState(false)

  const { finalPrice, vehicleInfo } = modalData

  useEffect(() => {
    const keys = Object.keys(vehicleInfo)
    const included = keys.filter(element => requiredFields.includes(element));
    if (isFinite(finalPrice) && included.length == requiredFields.length) {
      setRenderizable(true)
    }
  }, [])

  return renderizable
}